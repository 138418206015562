<template>
  <div class="message">
    You are <span class="data">{{ getQueuePosition() }}</span> in line, with an
    approximate wait of <span class="data">{{ getQueueLength() }}</span
    >. <Icon :path="mdiAsterisk" :size="11" />
    <div class="note">
      <Icon :path="mdiAsterisk" :size="7" />
      Queue positions and timings are approximate.
    </div>
  </div>
</template>

<script>
  import { queuePosition, queueLength } from "@/helpers/queue";
  import { mdiAsterisk } from "@mdi/js";
  export default {
    data() {
      return { mdiAsterisk };
    },

    props: {
      timings: {
        type: Object,
      },
    },

    methods: {
      getQueuePosition() {
        return queuePosition(this.timings.queuePosition);
      },
      getQueueLength() {
        return `${queueLength(this.timings.estimated.waitMinutes)} minutes`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .message {
    margin: 0 1em;
    padding: 0.5rem 0.75rem;
    @include flex($dir: row, $jc: flex-start, $wrap: wrap);
    color: $col_alpha-darker;
    border-radius: $card_radius;
    background: #f1f4f2;
    display: block;

    .data {
      font-family: "Stolzl Medium";
      font-weight: 900;
    }

    .icon-wrapper {
      position: absolute;
    }
    .loading-spinner {
      margin-top: -25px;
    }

    .note {
      font-size: 0.6em;
      text-align: right;
      display: block;

      .icon-wrapper {
        margin-left: -10px;
      }
    }
  }
</style>
