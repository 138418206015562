import { ordinal, round, RoundDirection } from "@tucktrucks/core";

export function queuePosition(position) {
  return ordinal(position);
}

export function queueLength(minutes) {
  if (minutes < 5) {
    return "a few";
  }

  const low = round(minutes, 5, RoundDirection.Floor);
  const high = round(minutes + 7.5, 5, RoundDirection.Ceiling);

  return `${low}-${high}`;
}
