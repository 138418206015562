<template>
  <div
    class="order-status-cart"
    :class="{ 'order-status-cart_active': isActive }"
    ref="container">
    <div class="order-status-cart-header" ref="header">
      <div class="order-status-cart-header__icon">
        <Icon :path="mdiFood" :size="18" class="cart-icon" />
        Order Number: #{{ order?.number }}
      </div>
      <Icon
        :path="mdiClose"
        :size="22"
        @click="$emit('closeCart')"
        class="close-icon" />
    </div>

    <div class="order-summary-wrap" ref="summaryTable">
      <table class="order-summary">
        <tbody class="order-summary-body" ref="summaryBody">
          <tr v-for="item in order.items" :key="item.id">
            <td class="column">
              <div class="row space-between">
                <div class="row align-flex-start cart-item-name-amount">
                  <span class="cart-item-name"> {{ item.name }}</span>
                  <div class="cart-item-amount align-center">
                    <span class="count flex-center">×{{ item.amount }}</span>
                  </div>
                </div>
                <div class="cart_price">
                  {{ this.formatMoney(item.price * item.amount) }}
                </div>
              </div>
              <div
                class="cart-item-component-alterations"
                v-if="item.componentAlterations?.length">
                <ul>
                  <li
                    v-for="component of item.componentAlterations"
                    :key="`${item.id}.${component.id}`"
                    class="row space-between">
                    <span class="cart-alteration-name">
                      {{ this.renderComponentAlterationLabel(component) }}
                    </span>
                    <span class="extras">
                      {{
                        component.priceToAdd !== 0
                          ? this.formatMoney(
                              component.priceToAdd *
                                component.levelDelta *
                                item.amount
                            )
                          : "Free"
                      }}
                    </span>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot ref="tfoot">
          <tr>
            <td colspan="2">
              <h4>Subtotal</h4>
            </td>
            <td class="cart_price">
              <h4>{{ formatMoney(order.subtotal) }}</h4>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="extras">Service Charge</td>
            <td class="cart_price extras">
              {{ formatMoney(order?.serviceCharge?.amount) }}
            </td>
          </tr>

          <transition name="fade">
            <tr v-if="order.charityDonations?.amount > 0">
              <td class="extras">
                <div class="row">
                  <Icon
                    :path="mdiHandHeart"
                    :size="18"
                    class="row__icon"
                    fillColor="#777" />
                  {{ order.charityDonations?.name }}
                </div>
              </td>
              <td class="cart-item-amount"></td>
              <td class="cart_price extras">
                <div class="row donation-summary">
                  {{ formatMoney(order.charityDonations?.amount) }}
                </div>
              </td>
            </tr>
          </transition>

          <transition name="fade">
            <tr v-if="order?.vouchers?.amount > 0">
              <td class="extras reduction" colspan="2">
                <div class="row">
                  <Icon
                    :path="mdiTag"
                    :size="18"
                    fillColor="#0b7162"
                    class="promotion__icon" />
                  {{ order?.vouchers?.name }}
                </div>
              </td>

              <td class="cart_price extras reduction">
                {{ formatMoney(-order?.vouchers?.amount ?? 0) }}
              </td>
            </tr>
          </transition>

          <tr>
            <td colspan="2">
              <h3>Total</h3>
            </td>
            <td class="cart_price">
              <h3>
                {{ formatMoney(order.total - (order?.vouchers?.amount ?? 0)) }}
              </h3>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  import {
    mdiFood,
    mdiClose,
    mdiInformation,
    mdiTag,
    mdiHandHeart,
  } from "@mdi/js";

  export default {
    data() {
      return {
        mdiFood,
        mdiClose,
        mdiTag,
        mdiHandHeart,
        mdiInformation,
        activeFirstFired: true,
        summaryBodyMaxHeight: null,
      };
    },
    components: {},
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      order: {
        type: Object,
      },
    },
    watch: {
      isActive: function () {
        if (this.isActive && this.activeFirstFired) {
          this.setCartSummaryMaxHeight();
          this.activeFirstFired = false;
        }

        document.body.style.overflow = this.isActive ? "hidden" : "auto";
      },
    },

    methods: {
      renderComponentAlterationLabel(component) {
        const { levelDelta, name } = component;

        let plusOrMinus = "+";
        let amount = "";

        if (levelDelta <= 0) plusOrMinus = "No";
        if (levelDelta > 1) {
          let str = levelDelta.toString().substring(0);
          amount = " (×" + str + ")";
        }

        return plusOrMinus === "No"
          ? `${plusOrMinus} ${name}`
          : `${plusOrMinus} ${name} ${amount}`;
      },

      setCartSummaryMaxHeight() {
        const containerHeight = this.$refs["container"].clientHeight;
        const summaryBody = this.$refs["summaryBody"];
        const summaryTable = this.$refs["summaryTable"];
        const headerHeight = this.$refs["header"].clientHeight;
        const summaryTableHeight = containerHeight - headerHeight - 60;
        const tfootHeight = this.$refs["tfoot"].clientHeight;

        summaryTable.style.height = summaryTableHeight + "px";

        if (summaryBody.clientHeight > summaryTableHeight - tfootHeight) {
          const summaryBodyMaxHeight = summaryTableHeight - tfootHeight + "px";
          this.summaryBodyMaxHeight = summaryBodyMaxHeight;
          summaryBody.style.height = summaryBodyMaxHeight;
        }
      },
    },
    mounted() {
      window.addEventListener("resize", () => {
        this.$emit("closeCart");

        if (this.isActive) {
          setTimeout(() => {
            this.activeFirstFired = true;
            this.setCartSummaryMaxHeight();
          }, 100);
        }
      });
    },
    unmounted() {
      window.removeEventListener("resize", () => {
        this.setCartSummaryMaxHeight();
      });
    },
  };
</script>

<style lang="scss" scoped>
  .order-status-cart {
    padding: 1rem !important;
    box-shadow: $card_shadow;
    background: $col_white;
    border-radius: $section_radius;
    height: max-content;
    position: sticky;
    top: $navbar_height + 2rem;

    .mobileLayout &,
    .tabletLayout & {
      border-radius: 0;
      box-shadow: $popup_shadow;
      display: block;
      max-height: 60vh;
      width: 100%;
      position: fixed;
      bottom: -100%;
      left: 0;
      z-index: 12;
      overflow-y: auto;
      transition: all 0.15s;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
      top: unset;

      &_active {
        bottom: 0;
      }

      @media screen and (max-width: $mobile_max_width) {
        table.order-summary {
          margin-bottom: 1rem;
        }
      }
    }

    .cart-icon {
      margin-right: 0.15rem;
    }

    .close-icon {
      display: none;

      .mobileLayout &,
      .tabletLayout & {
        display: block;
        cursor: pointer;
      }
    }

    .order-status-cart-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      &__icon {
        display: flex;
        align-items: center;
        font-weight: 600;

        .mobileLayout &,
        .tabletLayout & {
          font-size: 0.875rem;
        }
      }
    }

    .cart-item-component-alterations {
      margin-bottom: -0.5rem;
      > ul {
        margin-top: 0.25rem;
      }
    }

    table.order-summary {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1rem;
      overflow: auto;
      position: relative;
      line-height: 1.2em;

      td {
        vertical-align: top;
        padding: 0.5rem 0;
      }

      tbody {
        overflow: auto;
        display: table-caption !important;
        max-height: 400px;
        .cart-active & {
          @media screen and (max-width: 960px) {
            height: 100vh; /* Fallback for browsers that do not support Custom Properties */
            height: calc(var(--vh, 1vh) * 100 - 300px);
            min-height: 250px;
          }
          @media screen and (max-width: 480px) {
            height: 100vh; /* Fallback for browsers that do not support Custom Properties */
            height: calc(var(--vh, 1vh) * 100 - 360px);
            min-height: 150px;
            max-height: 50vh;
          }
        }

        tr {
          display: inline-table;
          width: 100%;
        }
      }
      h4 {
        margin-bottom: 0;
      }

      tbody tr {
        border-bottom: 1px solid $col_grey_green;
      }

      .cart-item-amount {
        .count {
          font-size: 1em;
        }
      }

      tfoot {
        tr td {
          padding: 0.25rem 0;
        }
        tr:first-child td {
          padding: 1rem 0 0.2rem 0;
        }
      }

      .material-icons-outlined {
        font-size: 0.875rem;
      }

      .mobileLayout.cart-active &,
      .tabletLayout.cart-active & {
        @media screen and (max-width: 960px) {
          tfoot,
          tbody {
            display: flex !important;
            flex-direction: column;
            width: 100%;

            tr {
              display: flex !important;
              width: 98%;
              flex-direction: row;
              justify-content: space-between;
              font-size: 0.875rem;
              align-items: flex-start;
            }
          }
          tfoot {
            tr {
              width: 100%;
            }
          }
        }
      }
      .cart_price {
        text-align: right;
        width: 100px;
      }

      .extras {
        color: $col_faded-darker;
        font-size: 0.9rem;

        .service-charge-info {
          margin-left: 5px;
          position: relative;
        }

        .icon-wrapper {
          margin-right: 5px;
        }
      }

      .mobileLayout &,
      .tabletLayout & {
        z-index: 12;
        // min-width: 250px;

        tbody {
          td.cart-item-amount {
            width: 50px;
          }
        }
      }

      .mobileLayout .charity-promo-wrapper &,
      .tabletLayout .charity-promo-wrapper & {
        width: 250px;
      }

      .mobileLayout.cart-active &,
      .tabletLayout.cart-active & {
        display: block;
        tbody {
          tr {
            td.cart-item-name {
              display: revert;
              width: 50%;
              padding-bottom: 0.5rem;
              vertical-align: top;
              @media screen and (min-width: 425px) {
                width: 60%;
              }
            }
            td.cart-item-amount {
              display: flex;
            }
          }
        }
        tfoot {
          tr {
            td:first-child {
              width: 200px;
            }
          }
        }
      }
    }

    .cart-item-name {
      width: 50%;
      min-width: 200px;
      max-width: 400px;
      font-weight: 400;
      @media screen and (max-width: 1279px) and (min-width: 1024px) {
        width: 130px;
        min-width: auto;
        max-width: none;
      }
      @media screen and (max-width: 1023px) and (min-width: 961px) {
        width: 100px;
        min-width: auto;
        max-width: none;
      }
      @media screen and (max-width: 425px) {
        min-width: auto;
        max-width: none;
      }
    }

    .cart-alteration-name {
      width: 50%;
      min-width: 200px;
      max-width: 400px;
      font-weight: 400;
      @media screen and (max-width: 1023px) and (min-width: 961px) {
        width: 200px;
        min-width: auto;
        max-width: none;
      }
      @media screen and (max-width: 425px) {
        width: 150px !important;
        min-width: auto;
        max-width: none;
      }
      @media screen and (max-width: 374px) {
        width: 120px !important;
      }
    }

    td.cart-item-amount {
      position: relative;
      justify-content: end;
      @media screen and (max-width: 960px) {
        top: 0;
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .reduction {
      color: $col_alpha !important;
    }

    .donation-summary {
      justify-content: flex-end;
      align-items: center;
    }

    .cart-item-component-alterations {
      padding-top: 0 !important;
      & > ul {
        width: 100%;
        li {
          padding: 0.15rem 0;
          &:not(:last-child) {
            border-bottom: 1px dashed #ddd;
          }
          .extras {
            font-size: 0.8rem;
          }
        }
      }
    }

    .cart-item-name-amount {
      width: 100%;
    }
    @media screen and (max-width: $mobile_max_width) {
      table.order-summary {
        .cart-item-name {
          width: calc(100vw - 150px);
          min-width: 100px;
          max-width: 200px;
          font-size: 0.875rem;
        }
        .count {
          width: 30px;
          font-size: 0.8rem;
        }

        .cart_price {
          width: 70px;
        }
      }
    }
    @media screen and (max-width: $small_mobile_width) {
      table.order-summary {
        .cart-item-name {
          max-width: 100px;
        }
      }
    }

    table.order-summary {
      tbody.order-summary-body {
        margin-right: -1rem;
        padding-right: 1rem;
      }
      .cart_price {
        width: 70px;
      }
    }

    .row__icon {
      margin-right: 5px;
    }
  }
</style>
