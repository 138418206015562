<template>
  <ul class="receipts">
    <li class="receipts__item">
      <DownloadFile
        :path="mdiReceiptText"
        :text="'Items' + getVatWordingBasedOnVendor + ' Receipt'"
        :apiConfig="{
          endpoint: 'orders',
          action: 'downloadItemsReceipt',
          ifApiPublic: true,
          params: { orderId, orderKey },
        }"
        :disabled="receiptTypeDownloading === 'items'"
        @onDownloadEnd="this.receiptTypeDownloading = null"
        @onClick="this.receiptTypeDownloading = 'items'" />
    </li>
    <li
      v-if="
        detail.order?.channel !== 'WalkUp' && detail.order?.channel !== 'Phone'
      "
      class="receipts__item">
      <DownloadFile
        :path="mdiReceiptText"
        :text="'Service Charge' + getVatWordingBasedOnVendor + ' Receipt'"
        :apiConfig="{
          endpoint: 'orders',
          action: 'downloadServiceChargeReceipt',
          ifApiPublic: true,
          params: { orderId, orderKey },
        }"
        :disabled="receiptTypeDownloading === 'serviceCharge'"
        @onDownloadEnd="this.receiptTypeDownloading = null"
        @onClick="this.receiptTypeDownloading = 'serviceCharge'" />
    </li>
  </ul>
</template>

<script>
  import { mdiReceiptText } from "@mdi/js";
  import store from "@/store";
  import DownloadFile from "@/components/Files/DownloadFile.vue";

  export default {
    data() {
      return {
        receiptTypeDownloading: null,
        mdiReceiptText,
        orderId: this.$route.params.orderId,
        orderKey: this.$route.params.secret,
      };
    },
    computed: {
      getVatWordingBasedOnVendor() {
        return this.detail.vendor?.taxNumber ? "VAT" : "";
      },
    },
    props: {
      id: {
        type: Number,
      },
      detail: {
        type: Object,
      },
    },
    methods: {
      getItemsReceiptUrl() {
        return store.state.apiPublic.client.getEndpoint(
          `/orders/${this.orderId}/${this.orderKey}/receipt/items`
        );
      },
      getServiceChargeReceiptUrl() {
        return store.state.apiPublic.client.getEndpoint(
          `/orders/${this.orderId}/${this.orderKey}/receipt/service-charge`
        );
      },
    },
    components: { DownloadFile },
  };
</script>
