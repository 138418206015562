<template>
  <p class="mb-1">
    For further correspondence regarding your order, please contact
    <span class="bold">{{ detail.outlet.name }}</span> by
  </p>
  <a class="button-like mb-1" :href="`mailto:${detail.outlet.email}`">
    <Icon :path="mdiEmail" :size="18" />
    {{ detail.outlet.email }}
  </a>
  <a class="button-like" :href="`tel:${detail.outlet.phone}`">
    <Icon :path="mdiPhone" :size="18" />{{ detail.outlet.phone }}
  </a>
</template>

<script>
  import { mdiEmail, mdiPhone } from "@mdi/js";

  export default {
    data() {
      return {
        mdiEmail,
        mdiPhone,
      };
    },
    props: {
      detail: {
        type: Object,
      },
      id: {
        type: Number,
      },
    },
    emits: ["openConfirmModal"],
  };
</script>

<style lang="scss" scoped>
  p {
    font-size: 0.875rem;
  }

  a.button-like {
    border-radius: $button_radius;
    border: 1px solid black;
    display: block;
    width: 100%;
    max-width: 350px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $button_padding;
    gap: $icon_text_button_gap;
    font-size: $button_font_size;
    margin-left: auto;
    margin-right: auto;
    word-break: break-word;
    flex-wrap: wrap;
  }
</style>
