<template>
  <div class="status-section__progress" :class="channel?.toLowerCase()">
    <div v-if="!specialStatuses.includes(currentStatus)">
      <ul class="status-section__progress-bar">
        <li
          v-for="i in statusChannel.length - 1"
          :key="i"
          :style="{
            width: `calc(${100 / statusChannel.length - 1})`,
          }"
          :class="[
            { current: currentStep === i - 1 },
            { past: currentStep > i - 1 },
          ]"></li>
      </ul>

      <ul class="status-section__progress-title">
        <li
          v-for="(status, i) in statusChannel"
          :style="{
            width: 100 / statusChannel.length + '%',
          }"
          :key="i"
          :class="[{ on: currentStep === i }, { past: currentStep > i }]">
          <span class="status-section__progress-icon">
            <Icon :path="getStatusIconPath(status)" :size="18" />
          </span>
          <span>{{ getStatusWording(status) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/Icon";
  import {
    mdiTextBoxCheck,
    mdiGrill,
    mdiShopping,
    mdiBikeFast,
    mdiCheckAll,
    mdiTablePicnic,
  } from "@mdi/js";

  export default {
    components: {
      Icon,
    },
    data() {
      return {
        statuses: {
          Normal: ["Placed", "Producing", "ReadyForCollection", "Completed"],
          Delivery: [
            "Placed",
            "Producing",
            "InTransit",
            "ReadyForCollection",
            "Completed",
          ],
        },
        specialStatuses: ["Refunded", "Cancelled", "Rejected"],
        statusesToMatch: null,
        mdiTextBoxCheck,
        mdiGrill,
        mdiShopping,
        mdiBikeFast,
        mdiCheckAll,
        mdiTablePicnic,
      };
    },
    props: {
      currentStatus: {
        type: String,
      },
      channel: {
        type: String,
      },
    },
    computed: {
      currentStep() {
        if (this.channel == "Delivery") {
          if (this.currentStatus === "ReadyForCollection") {
            return this.statusesToMatch?.indexOf("Producing");
          }
          if (this.currentStatus === "DriverCollected") {
            return this.statusesToMatch?.indexOf("InTransit");
          }
        }
        return this.statusesToMatch?.indexOf(this.currentStatus);
      },
      statusChannel() {
        switch (this.channel) {
          case "Delivery":
            return this.statuses["Delivery"];
          default:
            return this.statuses["Normal"];
        }
      },
    },
    methods: {
      getStatusIconPath(status) {
        switch (status) {
          case "Placed":
            return mdiTextBoxCheck;

          case "Producing":
            return mdiGrill;

          case "ReadyForCollection":
            return this.channel == "TableService"
              ? mdiTablePicnic
              : mdiShopping;

          case "DriverCollected":
          case "InTransit":
            return mdiBikeFast;

          case "Completed":
            return mdiCheckAll;
          default:
            break;
        }
      },
      getStatusWording(status) {
        switch (status) {
          case "Producing":
            return "Producing";

          case "ReadyForCollection":
            return this.channel == "TableService" ? "Serving" : "Collect Now";

          case "DriverCollected":
          case "InTransit":
            return "In Transit";

          case "Placed":
            return "Confirmed";

          case "Completed":
            return "Complete";

          default:
            return this.status;
        }
      },
    },
    mounted() {
      this.statusesToMatch = [...this.statusChannel, ...this.specialStatuses];
    },
  };
</script>

<style lang="scss" scoped>
  .status-section__progress {
    padding: 1rem 0.75rem;
    max-width: 550px;
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: $regular_mobile_width - 1px) {
      padding: 1rem 0.25rem 2rem;
    }

    &-special {
      p {
        font-weight: 600;
      }
      &__status {
        text-transform: lowercase;
        font-weight: 600;
      }
    }

    &-bar {
      list-style: none;
      display: flex;
      width: calc(100% - 80px);
      margin: 0 auto;

      li {
        width: 33.33%;
        text-align: left;
        height: 3px;
        background: #e0e0e0;

        &.past {
          background: $col_beta-darker;
        }

        @keyframes grow {
          from {
            width: 0;
            opacity: 0;
          }
          to {
            width: 100%;
          }
        }

        &.current {
          &::before {
            display: block;
            border-radius: 0 3px 3px 0px;
            content: "";
            width: 0;
            background: $col_beta;
            opacity: 0.8;
            animation: grow 3s ease-in-out infinite backwards;
            height: 100%;
          }
        }
      }
    }

    &-title {
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin-top: -14px;
      gap: 1px;

      li {
        min-width: 60px;
        overflow: visible;
        font-size: 0.7rem;
        line-height: 1.1em;
        text-align: initial;
        color: $col_gray;
        position: relative;
        font-weight: 500;
        text-align: center;

        > span {
          text-align: center;
          .icon-wrapper {
            width: 26px;
            height: 26px;
            margin: 0 auto 0.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            border: 1px solid $col_disabled_gray;
          }
        }

        @media screen and (min-width: $mobile_max_width) {
          font-size: 0.875rem;
        }

        @media screen and (max-width: $regular_mobile_width - 1px) {
          font-size: 0.65rem;
          min-width: 50px;
        }

        &.on {
          color: black;

          span {
            font-weight: 600;
            .icon-wrapper {
              color: white !important;
              border-color: $col_beta-darker;
              background: $col_beta-darker;
            }
          }
        }

        &.past {
          color: black;

          span {
            font-weight: 600;
            .icon-wrapper {
              color: $col_beta-darker !important;
              border-color: $col_beta-darker;
              background: white;
            }
          }
        }
      }
    }
  }
</style>
