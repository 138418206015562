<template>
  <p class="mb-1">
    <span v-if="detail.order.status === 'Cancelled'">
      You order has been cancelled.
    </span>
    <span v-else-if="detail.order.status === 'Refunded'">
      Refund Submitted.
    </span>
    <span v-else>
      <span class="order-status_latest" v-if="!cancellable">
        This order is not eligible for cancellation.
      </span>
      <span>
        Orders can be cancelled no later than
        {{ this.getCancelWindowMessage }} before requested time.
      </span>
    </span>
  </p>
  <button
    v-if="
      !(
        detail.order.status == 'Cancelled' ||
        detail.order.status === 'Refunded' ||
        !cancellable ||
        detail.cancelling
      )
    "
    class="secondary outlined mb-1"
    @click="this.$emit('openConfirmModal', 'cancel')">
    Submit Cancellation
  </button>
  <button
    class="secondary outlined mb-1"
    v-if="
      refundable &&
      detail.order.status !== 'Cancelled' &&
      detail.order.status !== 'Refunded'
    "
    :disabled="detail.order.status === 'Refunded'"
    @click="this.$emit('openConfirmModal', 'refund')">
    {{ detail.order.status === "Refunded" ? "Request Sent" : "Request Refund" }}
  </button>
</template>

<script>
  export default {
    data() {
      return {
        cancellable: null,
        refundable: null,
      };
    },
    props: {
      detail: {
        type: Object,
      },
      id: {
        type: Number,
      },
    },
    watch: {
      detail: {
        handler: function () {
          this.checkEligibility();
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
      getCancelWindowMessage() {
        const minutes = this.detail?.service?.cancelWindowMinutes ?? 30;

        if (minutes <= 90) {
          return `${minutes} minutes`;
        }

        return `${Math.round(minutes / 60)} hours`;
      },
    },
    methods: {
      allowStatusChange(status) {
        return this.detail.order?.refundMode === status;
      },
      checkEligibility() {
        this.cancellable = this.allowStatusChange("Cancellable");
        this.refundable = this.allowStatusChange("Refundable");
      },
    },
    emits: ["openConfirmModal"],
    mounted() {
      this.checkEligibility();
    },
  };
</script>

<style lang="scss" scoped>
  p {
    font-size: 0.875rem;
  }
  button {
    width: 100%;
    margin: 0 auto;
  }
</style>
