<template>
  <IconButton
    :text="getText"
    :path="this.path || mdiFileDocumentOutline"
    :disabled="disabled"
    is-textStyle
    is-black
    @click="downloadSingleFile" />
</template>

<script>
  import { mdiFileDocumentOutline } from "@mdi/js";
  import IconButton from "@/components/Buttons/IconButton.vue";
  import { useToast } from "vue-toastification";
  import store from "@/store";

  export default {
    data() {
      return {
        ifForAggrid: this?.params?.rowIndex >= 0,
        ifDownloading: false,
        ifDownloadEnd: false,
        mdiFileDocumentOutline,
      };
    },

    props: {
      apiConfig: {
        // For Aggrid, sending { apiConfig } using cellRendererParams property
        type: Object,
        default: () => ({
          endpoint: "",
          action: "",
          ifApiPublic: false,
          params: {},
        }),
      },
      path: {
        type: String,
      },
      disabled: {
        type: Boolean,
      },
      text: {
        type: String,
        default: "Download",
      },
      params: {
        // Aggrid gets { this.params } as default props
        type: Object,
      },
    },

    components: {
      IconButton,
    },

    computed: {
      getText() {
        if (this.ifDownloading) return "Downloading...";
        return this.text;
      },
    },

    emits: ["onDownloadEnd", "onClick"],

    methods: {
      async downloadSingleFile() {
        this.ifDownloading = true;
        this.$emit("onClick");

        const { endpoint, action, ifApiPublic, params } = this.ifForAggrid
          ? this?.params?.apiConfig
          : this?.apiConfig;

        const api =
          store.state[ifApiPublic ? "apiPublic" : "apiPrivate"].client
            .endpoints[endpoint][action];

        try {
          const response = await api(...Object.values(params));

          if (response.status === 200) {
            const data = response.data;
            const contentDisposition = response.headers[
              "content-disposition"
            ];
            const fileName = contentDisposition
              .split("filename=")[1]
              .split(".")[0];

            const docUrl = this.getFileReady(
              data,
              fileName,
            );

            // update state for updating the text of Download button
            this.handleDownloadEnd({ docUrl });
          } else {
            throw new Error("Could not download the file.");
          }
        } catch (error) {
          this.handleDownloadEnd({ error });

          window.log.error(error);
          useToast().error(`Failed to download file.`);
        }
      },

      handleDownloadEnd(payload) {
        this.ifDownloading = false;
        this.ifDownloadEnd = true;

        if (this.ifForAggrid) {
          this.params.onDownloadEnd(payload);
        } else {
          this.$emit("onDownloadEnd", payload);
        }
      },

      getFileReady(data, fileName) {
        const docUrl = document.createElement("a");
        docUrl.href = window.URL.createObjectURL(data);
        docUrl.download = fileName;
        document.body.appendChild(docUrl);
        docUrl.click();

        return docUrl;
      },
    },

    mounted() {
      this.ifForAggrid = this?.params?.rowIndex >= 0;
    },
  };
</script>
