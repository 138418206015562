<template>
  <div
    class="full-screen order-status"
    ref="orderStatus"
    v-if="loaded && this.order != null"
    :class="{ 'accordion-focused': accordionFocused }">
    <div class="card">
      <div class="status-section">
        <div class="status-section__detail column">
          <div class="status-section__detail-status">
            <div class="status-section__detail-status-main">
              <h3 v-if="!specialStatus">
                Thank you for<br />your {{ getChannelWording }} Order.
              </h3>
              <h3 v-if="order.status === 'Refunded'">
                Your {{ getChannelWording }} Order: <br />Refund requested
              </h3>
              <h3 v-if="order.status === 'Cancelled'">
                Your {{ getChannelWording }} Order <br />has been Cancelled
              </h3>
            </div>
            <ul class="order-detail column">
              <li class="row">
                <p class="order-detail__title">Vendor:</p>
                <p class="order-detail__value">{{ vendor.name }}</p>
              </li>
              <li class="row">
                <p class="order-detail__title">Order No.:</p>
                <p class="order-detail__value">{{ order.number }}</p>
              </li>
              <li class="row" v-if="order?.channel === 'TableService'">
                <p class="order-detail__title">Table No.:</p>
                <p class="order-detail__value">
                  <Icon :path="mdiTablePicnic" :size="12" />
                  {{ order.tableNumber }}
                </p>
              </li>
              <li class="row">
                <p class="order-detail__title">Payment Total:</p>
                <p class="order-detail__value">
                  {{ formatMoney(order.total) }}
                </p>
              </li>
              <li class="row">
                <p
                  class="order-detail__title"
                  v-if="order.channel !== 'TableService'">
                  For:
                </p>
                <p class="order-detail__title" v-else>Placed At:</p>
                <p class="order-detail__value">
                  {{ formatDay(order?.timings.serviceDate) }}
                  {{ formatDate(order?.timings.serviceDate) }},

                  <template v-if="order.channel !== 'TableService'">
                    {{ formatTime(getTimingTime()) }}
                  </template>
                  <template v-else>
                    {{
                      formatTime(
                        new Date(order?.timings.placedAt).toLocaleTimeString(
                          "en-GB"
                        )
                      )
                    }}
                  </template>
                </p>
              </li>

              <li class="row" v-if="order?.channel == 'Delivery'">
                <p class="order-detail__title">Location:</p>
                <p class="order-detail__value">
                  {{ order?.dropOffLocationName }}
                </p>
              </li>
            </ul>
          </div>

          <QueuePosition v-if="showQueue" :timings="order.timings" />

          <ProgressBar
            v-if="!specialStatus"
            :currentStatus="order?.status"
            :channel="order?.channel" />

          <DelayedBar
            v-if="order?.timings?.delayedBy"
            :duration="order.timings.delayedBy" />

          <!-- <ClubAccount
            v-if="
              order.customerId > 0 && service?.clubPointRewardLevel !== null
            "
            :customerLevel="order.customerOrdersOutlet"
            :clubPointRewardLevel="service?.clubPointRewardLevel" /> -->
        </div>

        <button
          class="status-section__view-detail-button full-width"
          @click="orderIsActive = true">
          <Icon :path="mdiFood" :size="16" /> View Order Detail
        </button>
      </div>
      <div class="order-status-accordion-wrapper">
        <AccordionList
          v-model:state="state"
          :open-multiple-items="false"
          class="check-out-accordion">
          <AccordionItem
            v-for="(listItem, i) in content"
            :ref="'accordinItem' + i"
            :key="i"
            :defaultOpened="this.specialStatus || listItem.defaultOpened"
            :disabled="listItem.disabled">
            <template #summary>{{ listItem.title }}</template>
            <template #icon>
              <Icon :path="mdiChevronDown" :size="22" class="arrow" />
            </template>
            <component
              v-if="order !== null"
              :id="i"
              v-bind:is="listItem.component"
              :detail="{ order, outlet, cancelling, service }"
              @openConfirmModal="openConfirmModal"></component>
          </AccordionItem>
        </AccordionList>
        <teleport to="body">
          <transition name="fade">
            <div
              class="modal-mask"
              @click.prevent=""
              v-if="actionToBeConfirmed">
              <div class="modal-wrapper">
                <div
                  class="modal-container sm-width column flex-center"
                  @click.stop="">
                  <h3 class="mb-2">
                    {{ actionToBeConfirmed.toUpperCase() }} ORDER
                  </h3>
                  <div class="mb-2 centered">
                    <p>
                      Are you sure you wish to {{ actionToBeConfirmed }} your
                      order?
                    </p>
                    <form
                      class="refund-reason-form"
                      v-if="actionToBeConfirmed === 'refund'">
                      <label for="refund-reason">
                        Why would you like to refund
                      </label>
                      <select
                        name="refund-reason"
                        id="refund-reason"
                        v-model="refundReason">
                        <option value="wait-time">Waiting Time</option>
                        <option value="quality">Quality</option>
                        <option value="missing-item">Missing Item</option>
                      </select>
                    </form>
                  </div>
                  <div class="column button-group">
                    <button @click="this.closeConfirmModal()" class="outlined">
                      No
                    </button>
                    <button
                      @click="this.cancel()"
                      :disabled="
                        actionToBeConfirmed === 'refund' && !refundReason
                      ">
                      <LoadingSpinner v-if="this.cancelling" />
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
      </div>
    </div>
    <OrderCart
      v-if="loaded"
      :order="order"
      :isActive="orderIsActive"
      @closeCart="orderIsActive = false" />
  </div>
  <div v-else>
    <div v-if="loaded && this.order == null" class="card">
      <div class="status-section">
        <h4>Order could not be found.</h4>
      </div>
    </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/LoadingSpinner";
  import { mapGetters } from "vuex";
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import {
    mdiChevronDown,
    mdiFood,
    mdiClockTimeFive,
    mdiCalendar,
    mdiTablePicnic,
  } from "@mdi/js";
  //import { APIORDERTIMINGS_TIMINGMODE } from "@tucktrucks/platform-base-public";
  import OrderCart from "@/components/OrderStatus/OrderCart";
  import OrderStatusContact from "@/components/OrderStatus/OrderStatusContact";
  import OrderStatusRefundCancellation from "@/components/OrderStatus/OrderStatusRefundCancellation";
  import OrderStatusDownloadReceipts from "@/components/OrderStatus/OrderStatusDownloadReceipts";
  import QueuePosition from "@/components/OrderStatus/QueuePosition";
  import ProgressBar from "@/components/OrderStatus/ProgressBar";
  import DelayedBar from "@/components/OrderStatus/DelayedBar.vue";
  // import ClubAccount from "../components/OrderStatus/ClubAccount.vue";

  export default {
    data() {
      return {
        mdiChevronDown,
        mdiFood,
        mdiClockTimeFive,
        mdiCalendar,
        mdiTablePicnic,

        actionToBeConfirmed: null,
        orderId: this.$route.params.orderId,
        orderKey: this.$route.params.secret,
        loaded: false,
        service: null,
        serviceId: null,
        order: null,

        cancelling: false,
        vendor: {},
        outlet: {},
        currentStatus: null,
        numberOfStatuses: null,
        progressBarOffset: null,
        refundReason: "wait-time",
        isRefundCancelSectionExpanded: false,
        bookedForFuture: false,
        tableNumber: null,

        timer: null,
        orderActive: false,
        state: {},
        orderIsActive: false,
        donationIsValidated: false,
        accordionFocused: false,
        content: [
          {
            component: "OrderStatusContact",
            title: "Contact",
          },
          {
            component: "OrderStatusRefundCancellation",
            title: "Refund & Cancellation",
          },
          {
            component: "OrderStatusDownloadReceipts",
            title: "Download Receipts",
          },
        ],
      };
    },

    computed: {
      ...mapGetters({
        getOrderById: "repoOrders/getById",
        getServiceById: "repoServices/getById",
        getVendorById: "repoVendors/getById",
        getComponentById: "repoComponents/getById",
      }),

      showQueue() {
        if (this.order?.timings?.timingMode == null) {
          return false;
        }

        if (this.service?.options?.schedulingMode == "FixedSlots") {
          return false;
        }

        if (this.order.timings.timingMode == "Asap") {
          if (
            this.order.status == "Placed" ||
            this.order.status == "Confirmed" ||
            this.order.status == "Producing"
          ) {
            return true;
          }
        }

        return false;
      },

      specialStatus() {
        return (
          this.order?.status === "Cancelled" ||
          this.order?.status === "Refunded" ||
          this.order?.status === "Rejected"
        );
      },

      getOrderChannel() {
        const channel = this.order?.channel;
        switch (channel) {
          case "ClickCollect":
            return "Click & Collect";
          case "Delivery":
            return "Delivery";
          case "WalkUp":
            return "Walk Up";
          default:
            return "";
        }
      },

      getVatWordingBasedOnVendor() {
        return this.vendor?.taxNumber ? "VAT" : "";
      },

      getChannelWording() {
        const { channel } = this.order;

        switch (channel) {
          case "ClickCollect":
          case "ClickCollectAsap":
            return "Click & Collect";

          case "WalkUp":
            return "Walk Up";

          case "Delivery":
            return "Delivery";

          case "TableService":
            return "Table";

          default:
            return "";
        }
      },

      getStatusWording() {
        const { channel, status } = this.order;

        switch (status) {
          case "Producing":
            return "Being Produced...";

          case "ReadyForCollection":
            return channel === "Delivery"
              ? "Being Collected..."
              : "Ready For Collection!";

          case "DriverCollected":
          case "InTransit":
            return "In Transit!";

          case "Placed":
            return "Confirmed!";

          case "Completed":
            return "Complete!";

          default:
            return status;
        }
      },
    },

    components: {
      LoadingSpinner,
      OrderCart,
      OrderStatusContact,
      OrderStatusRefundCancellation,
      OrderStatusDownloadReceipts,
      QueuePosition,
      ProgressBar,
      DelayedBar,
      // ClubAccount,
    },

    props: {
      verticalLayout: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      state: function () {
        this.accordionFocused = Object.values(this.state).some(
          (state) => state === true
        );
      },
    },

    methods: {
      updateStatus(newStatus) {
        this.currentStatus = newStatus;
        this.order.status = newStatus;
      },

      async getOrder() {
        try {
          this.order = await this.fetchOrder();
          if (this.order == null) {
            window.log.error("Could not download order " + this.orderId);

            return;
          }

          await this.getOrderPrice();
          await this.fetchService();
          await this.fetchVendor();
          await this.fetchOutlet();
          await this.setOrderItems();
        } catch (e) {
          window.log.error(e);
        } finally {
          this.loaded = true;
        }
      },

      async fetchService() {
        this.service = await store.state.repoServices.repo.getById(
          this.order.serviceId
        );
      },

      async fetchOrder() {
        const orderData =
          await store.state.apiPublic.client.endpoints.orders.getbyId(
            this.orderId
          );
        const order = orderData.data.data;

        return order;
      },

      async fetchVendor() {
        const vendor = await store.state.repoVendors.repo.getById(
          this.service.vendor.id
        );

        this.vendor = {
          id: vendor.id,
          name: vendor.name,
          email: vendor.mainContact?.emailAddress,
          phone: vendor.mainContact?.phoneOffice,
          taxNumber: vendor.taxNumber,
        };

        this.serviceId = this.service.Id;
        this.currentStatus = this.order.status;
      },

      async fetchOutlet() {
        const outlet = await store.state.repoOutlets.repo.getById(
          this.service.outlet.id
        );

        this.outlet = {
          id: outlet.id,
          name: outlet.name,
          email: outlet.mainContact?.emailAddress,
          phone: outlet.mainContact?.phoneOffice,
          taxNumber: outlet.taxNumber,
        };
      },

      async setOrderItems() {
        for (const [index, item] of this.order.items.entries()) {
          store.state.apiPublic.client.endpoints.items
            .getbyId(item.itemId)
            .then((response) => {
              const apiItem = response.data.data;
              if (item.componentAlterations?.length > 0) {
                for (const [
                  altIndex,
                  alt,
                ] of item.componentAlterations.entries()) {
                  // get componentAlteration name from repo
                  this.getComponentById(alt.componentId).then((data) => {
                    // get componentAlteration priceToAdd from apiItem.components
                    const priceToAdd = apiItem.components.find(
                      (component) => component.componentId === alt.componentId
                    ).priceToAdd;

                    this.order.items[index].componentAlterations[altIndex] = {
                      ...alt,
                      name: data.name,
                      priceToAdd,
                    };
                  });
                }
              }
              this.order.items[index] = { ...item, ...apiItem };
            });
        }
      },

      async getOrderPrice() {
        try {
          if (this.orderId == 0) {
            throw new Error("Tried to resolved order pricing for orderId: 0");
          }

          const response =
            await store.state.apiPublic.client.endpoints.orders.getPricing(
              this.orderId,
              this.orderKey
            );

          const data = response.data.data;

          this.order = {
            ...this.order,
            charityDonations: data.charityDonations,
            subtotal: data.subTotal,
            total: data.total,
            serviceCharge: data.serviceCharge,
            vouchers: {
              ...this.order.vouchers,
              amount: data.promotionVouchers?.amount,
              name: data.promotionVouchers?.name,
            },
          };
        } catch (error) {
          window.log.error(error);
        }
      },

      openConfirmModal(actionName) {
        this.actionToBeConfirmed = actionName;
      },

      closeConfirmModal() {
        this.actionToBeConfirmed = null;
      },

      getTimingMessage() {
        return "Booked for:";
      },

      getTimingTime() {
        return this.order?.timings.selectedSlot;
      },

      cancel() {
        this.cancelling = true;

        if (this.actionToBeConfirmed !== "cancel") return this.refund();

        store.state.apiPublic.client.endpoints.orders
          .cancel(this.orderId, this.orderKey)
          .then((res) => {
            if (res.status >= 200 && res.status <= 204) {
              return this.getOrder();
            } else {
              return Promise.reject(res);
            }
          })
          .catch((error) => {
            window.log.error({ getOrders: error });
            const toast = useToast();
            toast.error("Something went wrong cancelling this order.");
          });

        this.closeConfirmModal();
        this.cancelling = false;
      },

      refund() {
        store.state.apiPublic.client.endpoints.orders
          .refund({ reason: this.refundReason }, this.orderId, this.orderKey)
          .then((res) => {
            if (res.status >= 200 && res.status <= 204) {
              return this.getOrder();
            } else {
              return Promise.reject(res);
            }
          })
          .catch((error) => {
            window.log.error({ getOrders: error });
            const toast = useToast();
            toast.error("Something went wrong processing this order refund.");
          });

        this.closeConfirmModal();
        this.cancelling = false;
      },
    },
    mounted() {
      this.getOrder();

      this.timer = setInterval(async () => {
        if (
          this.currentStatus === "Completed" ||
          this.currentStatus === "Cancelled"
        )
          return clearInterval(this.timer);

        const order = await this.fetchOrder();

        if (order == null) {
          return;
        }

        this.order.refundMode = order.refundMode;
        this.updateStatus(order.status);
      }, 10000);
    },
    beforeUnmount() {
      clearInterval(this.timer);
    },
  };
</script>

<style lang="scss">
  .order-status {
    padding: 2rem;
    display: flex;
    width: 100%;
    gap: 1rem;
    background: #f8f8f8;
    justify-content: center;

    .mobileLayout &,
    .tabletLayout & {
      padding: 0;
      height: calc(
        100vh - $navbar_height
      ); /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100 - $navbar_height);
    }

    .card {
      position: relative;
      padding: 0;
      overflow: hidden;
      border-radius: $section_radius;
      overflow-y: auto;

      .order-status-accordion-wrapper {
        width: 600px;

        .mobileLayout &,
        .tabletLayout & {
          width: 100%;
          box-shadow: none;
          border-radius: 0;
        }
      }

      .mobileLayout &,
      .tabletLayout & {
        width: 100%;
        height: auto;
        box-shadow: none;
        border-radius: 0;
        display: flex;
        flex-direction: column;

        .status-section {
          display: flex;
          flex-direction: column;

          &__detail {
            flex: 1;

            justify-content: center;
          }
        }
      }
    }

    .status-section {
      text-align: center;
      transition: 0.2s all;

      &__detail {
        padding: 2rem 0 1rem;
        color: $col_beta-darker;
        gap: 1rem;

        h1 {
          padding: 0.25rem 1rem 1rem;
          line-height: 1em;
          text-transform: capitalize;
          font-size: 2.5rem;
          font-weight: 600;

          @media screen and (max-width: $mobile_max_width) {
            font-size: 2.3rem;
          }
        }

        h2 {
          margin: 0 0.5rem;
          font-size: 2rem;
          text-transform: none;
        }

        .delivery {
          margin-top: 10px;
        }

        .status-section__detail-status-main {
          h3 {
            font-size: 1.3rem;
          }
        }

        p {
          color: $col_faded;
          margin: 0 0.5rem;
          font-size: 0.875rem;
        }
      }

      &__view-detail-button {
        display: none;

        border-radius: 0;
        background: #f5f5f5;
        color: $col_beta-darker;
        font-weight: 600;
        height: 50px;

        .mobileLayout &,
        .tabletLayout & {
          display: flex;
          gap: 0.5rem;
        }
      }
    }

    .mobileLayout &,
    .tabletLayout & {
      .status-section {
        flex: 1;
      }

      .order-status-accordion {
        flex: 2;
      }
    }
  }

  .mobileLayout .accordion-focused,
  .tabletLayout .accordion-focused {
    &.order-status {
      height: auto;
    }
  }

  .order-status {
    h3 {
      line-height: 1.2em;
    }

    .accordion-list {
      border-top: 1px solid $col_gray;

      .accordion-item {
        &--open {
          > .accordion-item__summary {
            pointer-events: initial;
            > .accordion-item__summary-icon {
              display: block;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .order-detail {
      width: 95%;
      max-width: 550px;
      margin: 1rem auto;

      > li {
        width: 100%;
        justify-content: space-between;
      }
      &__value {
        color: black !important;
        font-weight: 600;
        .mdi-icon {
          display: inline-block;
        }
      }
    }
  }
</style>
