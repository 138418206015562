<template>
  <div class="delayed-bar">
    <p class="delayed-bar__status">
      <Icon :path="mdiClockPlus" :size="16" />
      Estimated delay:
      <span>{{ delayedTime }}</span>
    </p>
  </div>
</template>

<script>
  import { mdiClockPlus } from "@mdi/js";

  export default {
    data() {
      return { mdiClockPlus };
    },
    props: { duration: { type: String } },
    computed: {
      delayedTime() {
        const times = this.duration.split(":").map((d) => Number(d));
        const days = times[0] != 0 ? times[0] + "day" : "";
        const hours = times[1] != 0 ? times[1] + "hr" : "";
        const mins = times[2] != 0 ? times[2] + "min" : "";
        const seconds = times[3] != 0 ? times[3] + "sec" : "";
        const result = days + hours + mins + seconds;

        return result != "" ? result : "a few minutes";
      },
    },
  };
</script>

<style lang="scss" scoped>
  .delayed-bar {
    color: $col_beta-darker;
    background: $col_beta-lightest;
    width: 95%;
    margin: 0 auto 1rem;
    max-width: 550px;
    border-radius: 5rem;

    &__status {
      @include flex($jc: center, $g: 0.5rem, $dir: row, $ai: center);
      margin: 0;
      padding: 0.5rem;
      span {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
</style>
